const _none = "none"
const _spin = "spin 1s linear infinite"
const _ping = "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite"
const _pulse = "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
const _bounce = "bounce 1s infinite"
const _gradient = "gradient 8s linear infinite"
const _meteor = "meteor linear infinite"
const _in = "zoom-in 0.2s cubic-bezier(0.19, 1.0, 0.22, 1.0)"
const _out = "zoom-out 0.2s cubic-bezier(0.19, 1.0, 0.22, 1.0)"
const config = { "none": _none, "spin": _spin, "ping": _ping, "pulse": _pulse, "bounce": _bounce, "gradient": _gradient, "meteor": _meteor, "in": _in, "out": _out, "accordion-down": "accordion-down 0.2s ease-out", "accordion-up": "accordion-up 0.2s ease-out", "collapsible-down": "collapsible-down 0.2s ease-in-out", "collapsible-up": "collapsible-up 0.2s ease-in-out", "border-beam": "border-beam calc(var(--duration)*1s) infinite linear", "slide-in-from-top": "slide-in-from-top 0.2s ease-out", "slide-out-to-top": "slide-out-to-top 0.2s ease-out",  }
export { config as default, _none, _spin, _ping, _pulse, _bounce, _gradient, _meteor, _in, _out }